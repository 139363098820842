<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="手术室"/>
        <div style="text-align: right">
            <el-button type="primary" size="medium" @click="handleAdd">叫号</el-button>
        </div>
        <div>
            <div v-for="(c,i) in data" :key="i">
                <div class="theater-title">{{c.name}}</div>
                <el-row>
                    <el-col :span="8" v-for="(t, j) in c.value" :key="j">
                        <el-card class="box-card">
                            <div slot="header" class="clearfix">
                                <span>{{t.name}}</span>
                                <el-button style="float: right; padding: 3px 0" type="text">今日手术 3/10</el-button>
                            </div>
                            <div v-for="(p, k) in t.value" :key="k" :style="`color: ${p.color}`">
                                {{p.time}} {{p.doctor}} {{p.patient}} {{p.name}}
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                data: [
                    {
                        name: '门诊手术室',
                        value: [
                            {
                                name: '门诊手术室1',
                                value: [
                                    {
                                        time: '08:00-08:30',
                                        doctor: '李医生',
                                        patient: '患者1',
                                        name: '鼻翼部BCC切除术',
                                        color: '#ccc'
                                    },
                                    {
                                        time: '08:30-09:30',
                                        doctor: '李医生',
                                        patient: '患者2',
                                        name: '鼻翼部BCC切除术',
                                        color: '#ccc'
                                    },
                                    {
                                        time: '09:30-10:00',
                                        doctor: '李医生',
                                        patient: '患者3',
                                        name: '皮肤肿物切除',
                                        color: '#67C23A'
                                    },
                                    {
                                        time: '10:00-10:30',
                                        doctor: '李医生',
                                        patient: '患者4',
                                        name: '皮肤肿物切除',
                                    },
                                    {
                                        time: '10:30-11:30',
                                        doctor: '李医生',
                                        patient: '患者5',
                                        name: '皮肤肿物Mohs',
                                    },
                                ]
                            },
                            {
                                name: '门诊手术室2',
                                value: [
                                    {
                                        time: '08:00-08:30',
                                        doctor: '彭医生',
                                        patient: '患者1',
                                        name: '皮肤肿物切除',
                                        color: '#ccc'
                                    },
                                    {
                                        time: '08:30-09:30',
                                        doctor: '彭医生',
                                        patient: '患者2',
                                        name: '皮肤肿物切除',
                                        color: '#ccc'
                                    },
                                    {
                                        time: '09:30-10:00',
                                        doctor: '彭医生',
                                        patient: '患者3',
                                        name: '鼻翼部BCC切除术',
                                        color: '#67C23A'
                                    },
                                    {
                                        time: '10:00-10:30',
                                        doctor: '彭医生',
                                        patient: '患者4',
                                        name: '皮肤肿物Mohs',
                                    },
                                    {
                                        time: '10:30-11:30',
                                        doctor: '李医生',
                                        patient: '患者5',
                                        name: '皮肤肿物切除',
                                    },
                                ]
                            },
                            {
                                name: '门诊手术室3',
                                value: [
                                    {
                                        time: '08:00-08:30',
                                        doctor: '李医生',
                                        patient: '患者1',
                                        name: '鼻翼部BCC切除术',
                                        color: '#ccc'
                                    },
                                    {
                                        time: '08:30-09:30',
                                        doctor: '李医生',
                                        patient: '患者2',
                                        name: '鼻翼部BCC切除术',
                                        color: '#ccc'
                                    },
                                    {
                                        time: '09:30-10:00',
                                        doctor: '李医生',
                                        patient: '患者3',
                                        name: '皮肤肿物切除',
                                        color: '#67C23A'
                                    },
                                    {
                                        time: '10:00-10:30',
                                        doctor: '李医生',
                                        patient: '患者4',
                                        name: '皮肤肿物切除',
                                    },
                                    {
                                        time: '10:30-11:30',
                                        doctor: '李医生',
                                        patient: '患者5',
                                        name: '皮肤肿物Mohs',
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        name: '住院2部手术室',
                        value: [
                            {
                                name: '住院2部手术室1',
                                value: [
                                    {
                                        time: '08:00-08:30',
                                        doctor: '李医生',
                                        patient: '患者1',
                                        name: '鼻翼部BCC切除术',
                                        color: '#ccc'
                                    },
                                    {
                                        time: '08:30-09:30',
                                        doctor: '李医生',
                                        patient: '患者2',
                                        name: '鼻翼部BCC切除术',
                                        color: '#ccc'
                                    },
                                    {
                                        time: '09:30-10:00',
                                        doctor: '李医生',
                                        patient: '患者3',
                                        name: '皮肤肿物切除',
                                        color: '#67C23A'
                                    },
                                    {
                                        time: '10:00-10:30',
                                        doctor: '李医生',
                                        patient: '患者4',
                                        name: '皮肤肿物切除',
                                    },
                                    {
                                        time: '10:30-11:30',
                                        doctor: '李医生',
                                        patient: '患者5',
                                        name: '皮肤肿物Mohs',
                                    },
                                ]
                            },
                            {
                                name: '住院2部手术室2',
                                value: [
                                    {
                                        time: '08:00-08:30',
                                        doctor: '李医生',
                                        patient: '患者1',
                                        name: '鼻翼部BCC切除术',
                                        color: '#ccc'
                                    },
                                    {
                                        time: '08:30-09:30',
                                        doctor: '李医生',
                                        patient: '患者2',
                                        name: '鼻翼部BCC切除术',
                                        color: '#ccc'
                                    },
                                    {
                                        time: '09:30-10:00',
                                        doctor: '李医生',
                                        patient: '患者3',
                                        name: '皮肤肿物切除',
                                        color: '#67C23A'
                                    },
                                    {
                                        time: '10:00-10:30',
                                        doctor: '李医生',
                                        patient: '患者4',
                                        name: '皮肤肿物切除',
                                    },
                                    {
                                        time: '10:30-11:30',
                                        doctor: '李医生',
                                        patient: '患者5',
                                        name: '皮肤肿物Mohs',
                                    },
                                ]
                            },
                            {
                                name: '住院2部手术室3',
                                value: [
                                    {
                                        time: '08:00-08:30',
                                        doctor: '李医生',
                                        patient: '患者1',
                                        name: '鼻翼部BCC切除术',
                                        color: '#ccc'
                                    },
                                    {
                                        time: '08:30-09:30',
                                        doctor: '李医生',
                                        patient: '患者2',
                                        name: '鼻翼部BCC切除术',
                                        color: '#ccc'
                                    },
                                    {
                                        time: '09:30-10:00',
                                        doctor: '李医生',
                                        patient: '患者3',
                                        name: '皮肤肿物切除',
                                        color: '#67C23A'
                                    },
                                    {
                                        time: '10:00-10:30',
                                        doctor: '李医生',
                                        patient: '患者4',
                                        name: '皮肤肿物切除',
                                    },
                                    {
                                        time: '10:30-11:30',
                                        doctor: '李医生',
                                        patient: '患者5',
                                        name: '皮肤肿物Mohs',
                                    },
                                ]
                            },
                        ]
                    }
                ]
            }
        },
        methods: {
            handleAdd(){
                this.$router.push('/main/theater/prepare')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .theater-title {
        padding: 20px;
        text-align: center;
    }
    .box-card {
        margin: 10px;
    }
</style>
